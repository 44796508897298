<template>
  <div class="container admin">
    <div class="mr-0 mt-2 d-flex justify-content-end ml-auto">
    <!-- <BreadCrumb/> -->
    </div>
    <div class="row admin-header">
      <div class="col-12">
        <h2>Support</h2>
        <h1>Frequently Asked Questions</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 legend">
        <h2><a href="#" data-scroll>Contents</a></h2>
        <p />
        <ul>
          <li><h3><a href="#about-header" data-scroll>About The App</a></h3></li>
          <li><h3><a href="#dashboard-header" data-scroll>The Dashboard</a></h3></li>
          <li><h3><a href="#company-header" data-scroll>Company Profiles</a></h3></li>
          <li><h3><a href="#client-header" data-scroll>Client Profiles</a></h3></li>
          <li><h3><a href="#jobs-header" data-scroll>Job Postings</a></h3></li>
          <li><h3><a href="#advert-header" data-scroll>RightAd&trade; Advertising</a></h3></li>
          <li><h3><a href="#qualifying-header" data-scroll>Qualifying Questions</a></h3></li>
          <li><h3><a href="#candidates-header" data-scroll>Candidates</a></h3></li>
          <li><h3><a href="#account-header" data-scroll>User Accounts</a></h3></li>
          <li><h3><a href="#billing-header" data-scroll>Billing</a></h3></li>
          <li><h3><a href="#career-header" data-scroll>Career Website</a></h3></li>
        </ul>
      </div>
      <div id="about-header" class="col-md-9 faq mt-5 mt-md-0">
        <br>
        <h3>ABOUT THE APP</h3>

        <h4>User Terms and Conditions</h4>
        <p>
          <router-link :to="{ name: 'TNC' }">Terms of Use</router-link>
        </p>
        <p>
          <router-link :to="{ name: 'Privacy' }">Privacy Agreement</router-link>
        </p>
        <br/>
        <h4>How is Werkberry different from other hiring platforms?</h4>

        <p>
        We are focused on the needs of the small to mid-sized employer! We will give you what you need to management your jobs, candidates and the hiring process. Simply sign-in and you are ready to go. 
        </p>
        
        <h6> Our Technology </h6>

        <p>
          Werkberry "levels the playing field" by giving you sophisticated tools to compete with the considerable investments larger enterprises make to find the best candidates:
        </p>

        <p>
        Quick Apply gets "just the facts" from candidates without wasting their time or yours.  It takes about 2 minutes for a Candidate to apply, 
        with or without a resume, even if all they have is a smart phone.
        </p>
         <p>
        Qualifying Questions lets you customize exactly what you're looking for, so candidates can be automatically scored based on the criteria 
        that's most important to you.
         </p>
        <p>RightAd&trade; uses candidate targeting technology to scour the internet, audio, visual, social and mobile to find the RIGHT candidate, in the RIGHT place, at the RIGHT time with the RIGHT opportunity - making everyone a potential candidate. 
        </p>

        <br/>
        <h4>Where does Werkberry find my candidates?</h4>

        <p>Werkberry uses behavior intelligence and tracking analytics to automatically put your Job Posting in front of the “right” passive and active candidates.</p>  

        <p>Werkberry works with premium job boards, job aggregators, social media, and digital advertising pathways to create a unique recruiting strategy from over 
          10,000 publishers. Candidates that apply to a job on Werkberry often come from multiple sources.</p> 

        <p>Werkberry is a combination of cutting-edge technology and experienced insight. Werkberry uses its technology and decades of recruiting knowledge to help 
          small businesses win the talent war, one job at a time! </p>

        <br/>
        <h4>What if my company has an ATS (Applicant Tracking System)?</h4>

        <p>
        Werkberry is a candidate discovery, pre-qualification, and hiring workflow management tool. An ATS is a complex piece of software typically used by larger enterprises 
        for regulatory compliance (EEOC, ADA, Veteran Status, etc.) and complex onboarding requirements that typically exceed the requirements of a small to medium sized business. 
        Werkberry happily operates in front of the ATS, generating more candidates for your requirements. If your organization uses an ATS, you can still use Werkberry to 
        help find you the candidates that will be entered into your ATS.
        
        Use our super easy, mobile friendly Quick Apply and Qualifying Questions to identify and phone screen the highest scoring candidates.  
        Then simply send them a link to your ATS to "continue the application process" with your ATS.
        </p>
        <br>

        <h4>Can Werkberry interface with my ATS or Onboarding Software?</h4> 

        <p>Possibly, please contact Customer Support at info@werkberry.com for details.</p>
        <div id="dashboard-header"></div>
        <br>
        <h3>THE DASHBOARD</h3>

        <h4>How do I get to my dashboard?</h4> 

        <p>Select "Dashboard" from the Menu Bar on the top of the screen to get to your Dashboard.</p>

        <p>If you have clients, you can access a client’s dashboards as follows:<br/>
          locate the <span style="color: #290087">Clients</span> section of your dashboard => 
          start typing the company name in the search bar => select the client's name to go directly to that client’s Dashboard.</p>
        <br/>
        <h4>How do I navigate from my dashboard?</h4>

        <p>You can navigate to everything from your DASHBOARD. Hover your curser over any <span  id="examplehover" style="color: #290087;">purple label</span> and our signature <span style="color: #3dae2b;">green</span> underline appears letting you know it is a hotlink. </p>

        <h6>Dashboard Hotlinks</h6> 
        <ul>
        <li>Company Name </li>

        <li>Job Title </li>

        <li>Candidate # </li>

        <li>Client Name </li>
        </ul>
        
        <h6>Shortcuts to Find a Client or a Job </h6>

        <p>
        Use our filter fields located throughout the Dashboard to quick bring up the job or client you are looking for. 
        </p>
        <br>
        <h6>Partners</h6>
        <p>
        All actions can be carried out based on which dashboard you are working from. If you want to make changes and/or additions to a client’s environment, 
        you first need to navigate to that client’s dashboard before making the changes.  </p>

        <div id="company-header"></div>
        <br>
        <h3>COMPANY PROFILES</h3>
        <h4>How do I create my Company Profile?</h4>

        <p>Please review the video tutorial below</p>

        <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe src="https://player.vimeo.com/video/615968648?h=5eb53320ec&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" allowfullscreen 
                  style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Creating Your Company Profile"></iframe>
        </div>
        <br/>
        <p/>
        <h4>How do I upload a Company logo?</h4> 

        <p>A Logo may be added in a Company Profile. The logo chosen for the profile will be also be used for all of the company's Job Postings. 
        We accept the following image formats: PNG's, GIF's, JPG's or SVG's, up to 512KB.</p>
        
        <p>If you need to replace or update a logo you previously saved, you can <a href="#edit-profile" data-scroll>edit the profile</a> and select 
        the "browse" link to open the image file selector.</p> 

        <p><span style="color: #3dae2b;">Hint:</span> Research has shown that Candidates are 50% more likely to apply to a Job Posting with a logo!</p> 

        <div id="edit-profile"></div>
        <br/>
        <h4>How do I edit a Profile?</h4>

        <p>Please review the video tutorial below</p>
        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/c4f513193d034aa3b33ca4633121ff91" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>
        <h6>Text Instructions:</h6>
        <p><em>Your Profile:</em><br/> From your Dashboard => Select your company’s name at the top left => 
        Select the <span style="color: #018FBE;">blue pencil icon </span> ( <img alt="pencil icon" role='image' src="@/assets/img/pencil.svg" height="20px"/> ) 
        to the right of your company's name => Make all necessary changes => select "Save &amp; Continue".</p>  

        <p><em>A Client Profile:</em><br/> From your Dashboard => find and select the client from the Client Section => 
        Select the Client's name to enter their dashboard. You will be redirected to the Client’s dashboard => 
        Select the client’s company name => select the <span style="color: #018FBE;">blue pencil icon </span> 
        ( <img alt="pencil icon" role='image' src="@/assets/img/pencil.svg" height="20px"/> ) to the right of the company's name => 
        Make all necessary changes => select "Save &amp; Continue".</p> 

        <br>
        <h4>Can I create separate Company Profiles under my Profile for subsidiaries, franchises, and clients?</h4>

        <p>Yes. Please see <a href="#client-header" data-scroll>Client Profiles</a> below.</p>
        
        <div id="client-header"></div>
        <br>
        <h3>CLIENT PROFILES</h3>

        <p>Werkberry was specifically designed to meet the needs of businesses with complex business structures such as multilayer holding companies, 
          franchise partnerships, multi-level subsidiaries, and managed clients. This flexibility permits organizations to streamline the provision of
          hiring services to any level of their organization. We call these managed sub-profiles "Client Profiles". 
          Contact us at <a style="text-decoration: underline;" href="mailto:info@werkberry.com">info@werkberry.com</a> to learn more.
        </p>

        <br/>
        <h4>Can a Client Profile have Client Profiles?</h4>

        <p>Yes! Company Profiles with Clients can configure a Client Profile as 'limited' or 'unlimited'.</p>

        <p><em>Limited:</em> The Client Profile is limited to its own client portal and cannot add a Client Profile to itself.</p>

        <p><em>Unlimited:</em> This Client Profile has the ability to add Client Profiles, and determine whether those clients are limited or unlimited.</p>

        <p>From the Dashboard: Find the Client's name in the Client Window. Select the "Limited" link to the right of the client’s name. 
          'Limited' will switch to 'Unlimited' indicating the client now has permission to add clients. 
        </p>
          
        <p>If you don’t see "Limited"/"Unlimited" under the client name, or if the value does not change when you select it, 
          then you do not have the necessary permissions to enable the Client to add Clients. 
          Contact us at <a style="text-decoration: underline;" href="mailto:info@werkberry.com">info@werkberry.com</a> for assistance.</p>

        <br/>
        <h4>How do I add a new client?</h4> 

        <p>Company Profiles that are configured to add Client Profiles can send a "Client Invitation" either directly to a prospective client to setup 
          their own profile or to a Teammate to setup a profile on the client's behalf.</p>

        <p><em>Client Self-Setup:</em><br/>In this scenario, you send an invite directly to the prospective Client. The recipient then creates their User Account, 
        creates their Company Profile, and sets up their company's Payment Methods.</p>

        <p>From the Partner Dashboard => Select "Add Client" => Enter Client Name, Client email address and Client name => 
        Select Role: "Admin with Payment Authorization" => Select "Send Invitation".</p>

        <p><em>What the Recipient Must Do:</em><br/>Open the email invitation => Select link => Select Accept (existing users) or Complete the "Sign Up" fields 
        (new users) => Save and Continue => Complete the Client Profile, (Company Details, Logo, Locations, &amp; Payment Methods sections) => 
        Select "Take me to my Dashboard".</p>

        <p><em>Setup on Behalf of Client:</em> <br/>In this scenario, you send a "Client Invitation" to yourself or a designated Teammate. 
        The recipient sets up the Client Profile on behalf of the client, then sends a "Teammate Invite" to the Client to complete the process.</p> 
        
        <p>From the Partner Dashboard => Select "Add Client" => Enter Client Name, <b>YOUR</b> (or a Teammate's) email address => 
        Select Role: "Admin" => Select "Send Invitation"</p>

        <p><em>What the Recipient Must Do:</em><br/>Open the email invitation => Select link => Select Accept => Complete the Client Profile => 
          Go to Dashboard => Select "Add Payment Admin" from Add Payment Method popup dialog => Enter the email address and name for the client's designated "Admin with Payment Authorization" => 
          Select Role: "Admin with Payment Authorization" => Select "Send Invitation".</p>

        <br/>
        <h4>What if my "Add Client" button is disabled?</h4> 

        <p>Please contact either your Werkberry Admin, your Service Partner or <a href="mailto:info@werkberry.com">info@werkberry.com</a> to determine if you are eligible for additional permissions.</p>
        <br/>
        <h4>How do I remove a client?</h4> 

        <p>Please contact Werkberry Support at <a href="mailto:info@werkberry.com">info@werkberry.com</a>.</p> 
        
        <div id="jobs-header"></div>
        <br>
        <h3>JOB POSTINGS</h3>

        <h4>How do I create a Job Posting?</h4>

        <p>Please review the video tutorial below</p>

        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/c689b74cea574f71bb93a7da75daf9ba" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>
        <h6>Text Instructions:</h6>
        <p>From the Company Dashboard: select “Post Job” => enter the appropriate information => select “Qualification Questions” => 
          Look through the questions library => Select and adjust the questions accordingly => select “Done” => You will be redirected to a Job Detail page.</p>

        <p><span style="color: #3dae2b;">Hint:</span> The "Post a Job" form will not enable the "Qualification Questions" button 
        until ALL required fields are completed and all values are properly formatted. Fields with invalid values will have a thin 
        red border around them and a description of the issue. Once all required fields have been entered correctly the 
        "Qualification Questions" button will turn purple to indicate you are ready to move forward to the next step.</p>

        <br/>
        <h4>How do I edit a Job Posting?</h4>

        <p>Please review the video tutorial below</p>

        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/9a77ae2f143a44d0a83c49874431ed97" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>

        <h6>Text Instructions:</h6>        
        <p>Select the job title from your Dashboard => Select the <span style="color: #018FBE;">blue pencil icon </span> 
        ( <img alt="pencil icon" role='image' src="@/assets/img/pencil.svg" height="20px"/> ) to the right of the Job Posting's title => 
        Make any necessary changes to the job => Select Qualification Questions => Make any necessary changes to the qualification 
        questions => Select Done => All changes will be saved and you will be redirected to the Job Detail view</p>

        <br/>
        <h4>Can I "hide" a Job Posting that's not ready to be published or is no longer required?</h4> 

        <p>Yes. Each Job Posting has a "Status" that determines where it can be seen. 
          This Status is set on the "Ad Exposure" tab of the Job Posting. Here is what each status means:</p>
        
        <ul>
        <li>Draft – Indicates that the Job Posting is not ready to be seen externally. Draft jobs can be found in the "Draft Jobs"
           section of your dashboard.
        </li>
        <li>Career Page – The Job Posting will be published to your Werkberry Career Page.</li>
        <li><span style="color: #290087;">RightAd&trade;</span> – The Job Posting is advertised using Werkberry’s candidate targeting technology.</li>
        <li>Close Listing – "Archives" the Job Posting for future use by moving the Job Posting to the "Closed Jobs" section of your dashboard and 
          removing it from the Career Page and RightAd&trade;.</li> 
        </ul>

        <p><span style="color: #3dae2b;">Hint:</span> A Job Posting can be published to a Werkberry Career Page <b>AND</b> Advertised simultaneously. 
        Setting a Job Posting to either "Draft" or "Closed" will automatically remove it from both.</p>

        <br/>
        <h4>Can I Share a Job Posting with someone directly?</h4> 

        <p>Yes! Select the job title from your dashboard => in the upper right-hand corner, select "Share" => 
          Fill out the "Share This Job" form => Select "Share". The recipient will receive a link to the same Job Posting
          that a candidate would see.</p>

        <p><span style="color: #3dae2b;">Hint:</span> You can send a Job Posting to yourself and then forward it to 
        whoever you want From your email.</p> 

        <div id="advert-header"></div>
        <br/>
        <h3>RIGHTAD&trade;</h3>
        <h4>How do I advertise a job with RightAd&trade;?</h4> 

        <p>Please review the video tutorial below</p>

        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/51cc9195bd4847f68bf285d65ad793d6" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>
        <h6>Text Instructions:</h6>
        <p>From the Job Details tab => Select the "Ad Exposure" tab => Ensure that "RightAd&trade;" is selected.</p>

        <br/>
        <h4>How can I tell if a job is currently advertised with RightAd&trade;?</h4> 
        <p>Please review the video tutorial below</p>

        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/af710394eacd4970953e54e8f51d0f57" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>

        <h6>Text Instructions:</h6>
        <p>From your Dashboard, look at the "Open Jobs" section. All Job Postings with the "Scope Berry" Icon 
          ( <img alt="Scope Berry Icon" role='image' src="@/assets/img/scopeberry.jpg" height="20px"/> ) are currently advertised with
          Werkberry’s RightAd&trade;.</p>

        <br/>
        <h4>How does the Aging Indicator Bar work?</h4> 
        <p>Please review the video tutorial below</p>

        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/73b8185f7a9c466c9c4fa0c0ac450e7a" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>

        <h6>Text Instructions:</h6>
        <p>A Job Posting advertised with RightAd&trade; will be active for 30 days. The Aging Indicator Bar will be visible on your dashboard's Job Posting. 
          The Aging Bar will shrink from right to left and change from Green (30 - 6 days remaining) to Yellow (5 - 3 days remaining) and finally Red (2 - 0 days remaining) 
          as the 30-days elapses. You can hover over the Aging Bar to see how many days are left on your 30-day RightAd&trade;. 
          
        <p><span style="color: #3dae2b;">Hint:</span> The Aging Bar indicator and "Scope Berry" icon will no longer be visible once the RightAd&trade; deactivates;</p>

        <br/>
        <h4>How do I stop advertising a job?</h4>
        <p>Please review the video tutorial below</p>

        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/af9c28c723444867af8edf0bfb7f17ee" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>

        <h6>Text Instructions:</h6>
        <p>From the Job Details tab => Select the "Ad Exposure" tab => Ensure that "RightAd&trade;" toggle switch is "off".</p>

        <br/>
        <h4>Can I use Werkberry to market a job without RightAd&trade;?</h4> 

        <p>Yes! Please review the video tutorial below</p>

        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/9173300fd5cc40b494b83298e2d2d347" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>

        <br/>
        <h4>Can I advertise a Job Posting to more than one location (multiple cities/regions)?</h4>
        
        <p>Yes. Please contact Werkberry Support at <a href="mailto:info@werkberry.com">info@werkberry.com</a> to learn more.</p> 

        <div id="qualifying-header"></div>
        <br>
        <h3>QUALIFYING QUESTIONS</h3>

        <h4>How do I add Qualifying Questions to a job application process?</h4> 

        <p><span style="color: #3dae2b;">Hint:</span> A well tuned set of Qualifying Questions will help instantly identify and rank Candidates based on 
        how well they align with your Organization's values and expectations for a particular role, without the need for an interview or a phone screen.
         The time you invest in tuning these questions will result in tremendous time savings when reviewing a large number of Candidates.</p>

        <p>Upon completing all required Job Posting fields => select "Qualifying Questions" => you will be directed to the Question Bank => 
          carefully review your Question Bank options and pick the question(s) that match your job’s “Must Haves” and “Good to Haves” by 
          selecting the purple (+) plus sign at the top right of each question.</p>

        <p>The selected question will automatically load into your Preview Questionnaire. From there you are able to customize your questions and 
          <a href="#weighted-score" data-scroll>weight</a> your answers.</p>

        <br/>
        <h4>Can I create a different set of Qualifying Questions for each of my jobs?</h4> 

        <p>Yes! Qualifying Questions must be configured for each Job Posting. This maximizes their value for finding the candidates that answered the questions you 
          rated as most important for that particular position. (Please see <a href="#weighted-score" data-scroll>What is a Weighted Score?</a> for more details, hints,
          and advice)</p>

        <br/>
        <h4>How are customizable questions configured?</h4> 

        <p>Some of the questions in the Question Bank can be customized to ask about a particular skill, discipline, or requirement. 
          These customizable questions require you to specify that skill, discipline, or requirement in a blank text box for that question in the "Preview Questionaire".
           Once you have finished typing in the desired answer, don't forget to <a href="#weighted-score" data-scroll>weight</a> your answers.</p> 

        <br>
        <h4>Can I Edit Qualifying Questions once a Job Posting is active?</h4> 

        <p>Yes. You can simple edit the current Job Posting and add, alter or remove its Qualifying Questions to improve the weighted scoring. This is a powerful technique
          for eliminating over- or under-screening, or to fine tune what you are looking for in a candidate.</p>

        <p><span style="color: #3dae2b;">Hint:</span> If the Qualifying Questions are changed while the Job Posting is active, earlier candidates will show the previous 
        questions, answers, and score, which will be different than the questions, answers and scoring of candidates that applied after the changes. This means you can 
        improve the usefulness and accuracy of the Qualifying Questions for a Job Posting over time, but it might make comparisons between candidates that answered 
        different questions more difficult.</p>

        <br>
        <h4>I don't see a Qualifying Question that I'd like to ask. Can I create a custom Qualifying Question?</h4> 

        <p>If you don’t see a question in the bank you would like, please contact Werkberry at <a href="mailto:info@werkberry.com">info@werkberry.com</a> to see if we can either 
        find a question that meets your needs or add your question to the library.
        </p>

        <div id="weighted-score"></div>
        <br/>
        <h4>What is a Weighted Score?</h4> 
        <p>A weighted score is generated from the Candidate's answers based upon how closely they match the specified values that were selected when configuring
           the Job Posting's Qualifying Questions. If a Candidate picks a "Deal Breaker" they will automatically be scored a "0" regardless of their other answers. 
           Barring that, Answers which are identified as a "Best Answer" will produce a significantly higher score than a "Good Answer", which in turn is better than 
           an "Acceptable Answer"</p> 

        <h6>Answer Weights:</h6>
        <ul>
          <li>Best Answer = Will strongly skew a candidate to a higher score.  Best reserved for the questions that are REALLY important for the Job's criteria.</li> 

          <li>Good Answer = [Default Answer] Second best score which will move the candidate up in the rankings.</li>  

          <li>Acceptable Answer = A score which will have minimal impact on the candidate's score.</li> 

          <li>Deal-Breaker = Will automatically disqualify a candidate that chooses that answer, regardless of what other answers were provided.</li>
        </ul>

        <p><span style="color: #3dae2b;">Hint #1:</span> If two answers have the same value, they will be weighted identically. This may be desirable in a multiple choice question, 
           (e.g., you are looking for entry level candidates with minimal or no experience, but are not interested in intermediate or advanced candidates), but it serves no purpose in a Yes No 
           question unless you simply want to read the candidate's answer out of curiosity. (In this case it would be best to set both answers as "Acceptable" so it has less effect
           on the other question's weighted score).
          </p> 

        <p><span style="color: #3dae2b;">Hint #2:</span> If you want to specify a "DealBreaker" for a Yes/No question, simply check the "Deal-Breaker" box under the answers, 
        and the "No" answer will be set to "Deal-Breaker" and the "Yes" answer will automatically be weighted as "Best Answer". (If it's really a Deal-Breaker, then "Yes" would
        normally produce the highest possible weighting for that question, but you can specify a lesser weighting for "Yes" if you want to). Multiple Choice questions also support 
        Deal-Breaker weightings (multiple answers can be weighted as "Deal-Breakers"), but you must weight the other answer individually.</p>

        <p><em>Weighting Example:</em><br/>A company wants to hire a Regional Manager for a territory is in trouble and needs a lot of "hands on" supervision. 
          The Candidate must possess a valid drivers license and insurance. The company has experienced retention issues with this position and believes a willingness to travel 
          is the most important consideration, and that candidates who have a willingness to work overtime and a willingness to relocate indicate an attitude that was shared by 
          effective "hands on" Regional Managers in the past. The company should score the valid license and insurance a "Deal Breaker", the willingness to travel's "Yes" as a 
          "Best Answer" (with "No" being an "Acceptable Answer"), and the willingness to work overtime and relocate as "Good Answers".  This ensures that a Candidate who is 
          willing to travel but not thrilled about relocating or working overtime will still outscore a Candidate who is willing to relocate and work overtime, but does not 
          want to travel.</p>

        <br/>
        <h4>Where can I see a candidate's responses to their Qualifying Questions?</h4> 

        <p>From candidate list view: select "View" under Responses => a candidate card will pop up with a list of the questions asked as well as the candidate’s answers.</p> 


        <div id="candidates-header"></div>
        <br>
        <h3>CANDIDATES</h3>

        <h4>How do I know if anyone has applied to my job?</h4> 

        <p>Please review the video tutorial below</p>
        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/5a5071f2026344509b0644356b9703e1" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>

        <h6>Text Instructions:</h6>
        <p>From the Company Dashboard: locate the job list view => under "Open Jobs", there is a list of all active jobs => Each job title features the number of candidates who have applied since the job was advertised.</p>

        <p>Select "Candidate #" from the Dashboard to be redirected to that job’s candidate management view.</p> 
        <br>
        <h4>Where do I review candidate information?</h4>

        <h6>Two Options</h6>
        <p><ul>
          <li>From the Company Dashboard: locate the job list view => select "Candidates #" => you will be redirected to the candidates for that specific job.</li> 
          <br>
          <li>Job Detail view => select the "Candidates" tab in <span style="color: #018fbe">ocean blue</span> on the right-hand side => select "Candidates".</li> 
        </ul></p>
        <br>
      
        <h4>How do I hire a candidate and stop advertising the job?</h4>

        <p>
        From the Company Dashboard: under OPEN jobs, select "Candidates" => in your candidate management view, select "Candidate Status" and change it to "Hired" => select "Job Detail" => select "Visibility" => select "Close Listing" => Select the “X” at the top right to be redirected back to the job detail page and then select Dashboard. 
        </p>
        <br>
        <h4>Why is telling Werkberry that I HIRED important?</h4> 

        <p>Werkberry gets smarter with every hire you make. Finishing the process, will provide your organization with meaningful analytic data and Werkberry with more information about what candidate experience, characteristics and abilities best fit your open role and culture.</p> 
        <br>

        <h4>How do I manage my candidates?</h4> 

        <p>From the Company Dashboard: Job Detail view => select "Candidate #" at the job row level at the far right => you will now be in Candidate Management view, where you can:</p>

        <ul><li>Rate your candidate</li> 

        <li>Share This Candidate</li> 

        <li>Manage Candidates Status</li> 

        <li>View Qualification Questions and Score</li> 

        <li>View Candidate’s Resume</li> 

        <li>View/add Candidate Notes</li></ul>
        <br>
        <h4>How do I view candidate details?</h4> 

        <p>From Candidate Management, at the individual candidate row: select "View" under "Response" => The Candidate Window will pop up, where you will find the following information: 

        <ul><li>Candidate’s Name</li> 

        <li>Candidates Contact Information</li> 

        <li>Qualification Questions and Answers</li> 

        <li>Candidate Status</li> 

        <li>Candidate Resume and/or Experience</li> 

        <li>Candidate Ratings</li></ul></p>
        <br>
        <h4>How do I view candidates?</h4> 

        <h6>Two Options</h6>

        <p><ol><li>From your Dashboard: select "Candidate #" => you are now in candidate management view.</li> 
          <br>
        <li>Select "Job Title" => you are now in the job detail view => select the <span style="color: #018fbe">ocean blue</span> "Candidates" tab to go to candidate management view.</li></ol></p>  
        
        <h6>Candidate View</h6> 

        <p>The horizontal boxes at the top of the candidate management view helps visualize the job's overall candidate funnel and quickly view candidate status.</p>  
        <br>
        <h4>How do I rate my candidates?</h4> 

        <p>Rating candidates improves our matching technology and lets Werkberry know that we are getting it right. Your candidate ratings are only visible to users on your account.</p>  

        <p>From the candidate management view, at the individual candidate row level, select your rating option (checkmark, question mark and x).</p> 

        <ul>
        <li><span style="color: #DC3545">X</span> = Not Hiring</li> 

        <li><span style="color: #018fbe">?</span> = Good, but I need more information</li> 

        <li><span style="color: #3DAE2B">√</span> = Candidate matches requirements!</li></ul> 
        <br>
        <h4>Why is rating your candidate so important?</h4> 

        <p>Werkberry gets smarter every time you share your selections with us. The more Werkberry understands what look goods to your organization the better the candidates will become.</p> 
        <br>
        <h4>How do I Manage a Candidate Through the Hiring Process?</h4>

        <p>Please review the video tutorial below</p>
        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/13d2f3a544384b7dbeb8169cdb7e17c3" 
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div> 
        <br/>

        <h6>Text Instructions:</h6>
        <p>The status dropdown list gives you the ability to move your candidate through a simple hiring process. By selecting the dropdown box under STATUS, you can change where in the workflow your candidate should be and use it as an indication of steps completed:</p>    

        <p>From the candidate management view: select the "Status" dropdown to select from the following: 
          <br><br>
        <ul><li>New: Unread = New candidate that hasn't been reviewed</li> 
        <br>
        <li>New: Read = Reviewed the resume but waiting for more information or additional consideration before moving the candidate to the next step</li> 
        <br>
        <li>Evaluating = Candidate has been reviewed and initial candidate contact has been made</li> 
        <br>
        <li>Evaluating: Phone Screen = Phone screen has been completed</li> 
        <br>
        <li>Evaluating: Interview = Interview has been completed</li> 
        <br>
        <li>Offered = Offer made and/or offer letter sent to candidate</li> 
        <br>
        <li>Offered: Declined Offer = Offer extended but candidate declined the offer or was a no show</li>  
        <br>
        <li>Hired = Candidate passed background, testing and signed an offer letter</li> 
        <br>
        <li>Not Hired = Candidate didn’t fit minimum qualification and/or candidate wasn’t a cultural fit.</li></ul></p> 
        <br>

        <br>
        <h4>How do I forward candidate information and/or a resume to an internal manager for review?</h4> 

        <p>From the candidate management view: locate the candidate that you want to forward => on the right of the candidate’s row, under "More", select the three small dots (•••) => from the drop down, select "Share this Candidate".</p> 

        <p>In the pop-up dialog, fill in the recipient's email address and optional message => Select "Share".</p> 

        <p>An email will be generated with the candidate details and a link to the candidate within Werkberry and/or a download link to the candidate's resume.</p> 

        <p>If the recipient isn’t already a Werkberry user, they will not have access to view the Candidate information from within Werkberry or add notes. (Please see <a href="#addclient" style="color: #290087">How do I add a new client?</a>). Then follow the same instructions as <a href="#activateclient" style="color: #290087">How do I activate an existing client's account?</a>).</p> 
        <br>
        <h4>How do I add candidate notes?</h4> 

        <p>From the candidate management view: locate the the candidate to which you want to add notes => on the right of the candidate’s row, under More, select the three small dots (•••) => from the drop down, select "Notes" => in the Notes pop-up window, select "Add+" to access the text box => add your note => select "Save". => When you are finished with your note, select the “X” to close out of the Notes window.</p> 
        
        <p>All notes will be date stamped with the user’s name who entered the note.</p> 

        <div id="account-header"></div>
        <br>
        <h3>USER ACCOUNTS</h3>

        <h4>How do I get an account with Werkberry?</h4>

        <p>Werkberry is currently invite-only. To be considered, please request access through our quick and easy 
          <a style="text-decoration: underline;" href="https://werkberry.com/request-access-to-werkberry/" target="_blank">request form</a>. 
          We will review your request within 1 business day and get back to you with a response.</p>
        <br/>
        <h4>How do I accept a Werkberry invitation?</h4>

        <p>From your email, look for an invite from "custserv@werkberry.com". (If you don't see it, it's possible it went to your spam folder) 
          You can either click on the link or copy and paste the link it into your browser's address bar. The link will be validated by Werkberry.
          Upon validation, you will be taken to a registration form for creating your Werkberry credentials. Invitations expire after 30 days.</p>
        
        <br/>
        <h4>Can I change my email address/username for my account?</h4>

        <p>Please contact Werkberry Support at <a href="mailto:info@werkberry.com">info@werkberry.com for assistance.</a></p>
        <br/>
        <h4>How do I reset my account password?</h4>
        <p>From the Login screen => select "Forgot Password?" => enter the email address/username you use to sign in) => select "Send Reset Link" =>
          Look for an email from "custserve@werkberry.com". (You may need to check your Spam/Junk folder). Follow that Link to reset your password.</p>

        <p><span style="color: #3dae2b;">Hint:</span> Password reset links expire in 4 hours.</p>

        <div id="add-teammate" />
        <br/>

        <h4>How do I add a Teammate?</h4>

        <p><span style="color: #3dae2b;">Hint:</span> Make sure you are in the correct profile when inviting a new Teammate.</p>

        <p>Please review the video tutorial below</p>
        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
            <iframe src="https://www.loom.com/embed/e76ebef622f84a54b14d22ba89818b6e"
                    frameborder="0" 
                    webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <br/>
        <h6>Text instructions:</h6> 
        
        <p>Navigate to the company's Dashboard => Select the company's name in the Top Left to access the Company's Profile page  
          => select "Invite Teammate" => Enter the teammate’s email address  =>  Enter First and Last Name  => Select Role  => Select "Send Invitation"</p> 
        <br>

        <h6>Roles</h6>
        <em>Hiring Manager</em>
        <p>Review, notate, rate &amp; advance candidates through an organization’s hiring workflow.</p>

        <em>Recruiter</em>
        <p>Review, notate, rate &amp; advance candidates through an organization’s hiring workflow
          View, Create, Edit, Advertise &amp; Close an organization’s Job Postings.</p>

        <em>Admin</em>
        <p>Review, notate, rate &amp; advance candidates through an organization’s hiring workflow
          View, Create, Edit, Advertise &amp; Close an organization’s Job Postings
          Create, View, &amp; Edit your organization’s Profile and Invite Teammates to your organization.</p>

        <em>Admin with Payment Authorization</em>
        <p>Review, notate, rate &amp; advance candidates through an organization’s hiring workflow
          View, Create, Edit, Advertise &amp; Close an organization’s Job Postings
          Create, View, &amp; Edit your organization’s Profile
          Invite Teammates to your organization
          Add, Update, or Remove Payment methods for your organization</p>

        <br/>
        <h4>Can I reset a password for another Teammate?</h4>

        <p>We don't recommend that users reset other user's passwords for security reasons, especially since the Teammate should be able to reset their own password, 
          but you can either request a reset on their behalf (and ask them to forward the reset email), or contact Werkberry Support at 
          <a href="mailto:info@werkberry.com">info@werkberry.com</a>.
        </p>

        <br/>
        <h4>How do I change the permissions for an Account?</h4>

        <p>Please contact Werkberry Support at <a href="mailto:info@werkberry.com">info@werkberry.com</a></p> 

        <br/>
        <h4>How do I remove a Teammate?</h4> 

        <p>Please contact Werkberry Support at <a href="mailto:info@werkberry.com">info@werkberry.com</a></p>
        

        <div id="billing-header"></div>
        <br>
        <h3>BILLING</h3>
        
        <h4>How am I billed?</h4>

        <p>When you setup your Company Profile, you will be asked to provide a Payment Method (either Credit, Debit, or ACH). The "Primary" Payment Method (typically the first one entered) 
          will be billed automatically every month once your 30 day trial expires. RightAd&trade;'s are billed against the Primary Payment Method immediately upon activation.</p>
        
        <p><span style="color: #3dae2b;">Hint:</span> We have multiple billing options and arrangements. Contact us at 
        <a style="text-decoration: underline;" href="mailto:info@werkberry.com">info@werkberry.com</a> to learn more.
        </p>
        <br/>
        <h4>When does my 30-day FREE monthly Subscription start?</h4>

        <p>The 30 days begins when you have entered a verified payment method.</p>

        <br/>
        <h4>Will I be charged anything if I cancel before the 30-day FREE monthly subscription is over?</h4>

        <p>You will only be charged for any RightAd&trade; purchases you made during the trial period.</p>

        <br/>
        <h4>How do I change a Company's Payment Methods?</h4>
        <p></p>

        <p>From your Dashboard, Access and <a href="#edit-profile" data-scroll>edit the desired company's Profile</a>. Scroll to the "Payment Methods" section.</p>

        <p>You can add a Payment Method from the right side context menu (three small dots ••• in a blue circle) in the Payment Methods titlebar.</p>
        
        <p>You can delete a Payment Method or assign a verified Payment Method as the "Primary" Payment Method from the context menu (three small dots ••• in a blue circle) for that Payment Method.</p>
        
        <p>If you do not see the Payment Methods section, or if the section is locked and you believe it should be accessible, 
          please contact Werkberry at <a style="text-decoration: underline;" href="mailto:info@werkberry.com">info@werkberry.com</a> for assistance.</p>

        <div id="career-header"></div>
        <br>
        <h3>CAREER WEBSITE</h3>

        <h4>How do I embed my Werkberry Career Page on my own website?</h4>

        <div style="position: relative; padding-bottom: 62.5%; height: 0">
          <iframe
            src="https://www.loom.com/embed/72c43d02747d490bad89a3648e527a99"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              margin-top: 2%;
              margin-bottom: 5%;
            "
          ></iframe>
        </div>
        <br><br>

        <h6>Text Instructions:</h6>
        <br>
        <h6>Using an Iframe to embed my Werkberry Career Page</h6>
          <p>
        <ol>
      <li>You will need to create an iframe. If you are unfamiliar with <a target="_blank" style="text-decoration: underline;" href="https://www.w3schools.com/html/html_iframe.asp">iframes</a>, they are simply a bit of HTML that allows you to embed a webpage inside another webpage. You can copy and paste the iframe below, but be sure to replace the <em>src=" "</em> with the url from your Werkberry Career Page.</li>
        <br><br>

        <span style="color: #270089" >&lt;iframe title="Werkberry Career Page" src="your Werkberry career page url goes here" width="100%" height="900">&lt;/iframe></span>

        <br><br>
        <li>Place your iframe in your website's HTML on the appropriate career page. <br><em>Note: Our intention is for this to replace your company's career page, but you can place it wherever you find appropriate.</em></li> 
        <br><br>
        <li>If the sizing needs to be adjusted, you can try adjusting your iframe's <em>width=" "</em> and <em>height=" "</em> properties.</li>
        </ol>
        <br>
        If you have further questions, please contact <a href="mailto:info@werkberry.com">info@werkberry.com</a>.
        </p>

        <br>
        <h3><a href="#" data-scroll>back to top</a></h3>

        <!-- <div style="height: 1000px">&nbsp;</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SmoothScroll from "smooth-scroll";
//import Privacy from "@/views/Account/Privacy.vue";
// import BreadCrumb from '@/components/BreadCrumb.vue';

// eslint-disable-next-line
const scroll = new SmoothScroll('a[href*="#"]', {
  offset: 100,
  speed: 60,
  updateURL: false,
});

export default Vue.extend({
  name: "FAQ",
  components: {
    //Privacy
  }
});
</script>


<style scoped>
h1 {
  font-weight: 600;
  font-size: 24px;
  color: #1c1f2a;
}
div.legend {
  position: fixed;
}
.legend ul {
  list-style-type: none;
  padding-left: 0;
}
.legend h3 {
  cursor: pointer;
  font-weight: 500;
}
div.faq {
  margin-left: 200px;
}
.faq p,
.legend h3 {
  font-size: 16px;
}
.legend h3,
.faq h4 {
  color: #270089;
}
.faq h4 {
  font-size: 18px;
  font-weight: 500;
}
.faq h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1c1f2a;
}
@media only screen and (max-width: 767px) {
  div.legend {
    position: relative;
  }
  div.faq {
    margin-left: 0;
  }
}
</style>